import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"

function createMarkup(source) {
  return { __html: source }
}

const NewsletterTemplate = ({ data, location }) => (
  <Layout location={location}>
    <SEO title={data.wordpressWpNewsletters.title}></SEO>
    <section className="case-study">
      <div className="container">
        <div className="row">
          <div className="col col__12">
            <div
              dangerouslySetInnerHTML={createMarkup(
                data.wordpressWpNewsletters.acf.content
              )}
            ></div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query($id: String!) {
    wordpressWpNewsletters(id: { eq: $id }) {
      title
      acf {
        content
      }
    }
  }
`

export default NewsletterTemplate
